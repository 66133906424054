var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 pb-0"},[_c('div',{staticClass:"flex items-center mb-4 justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('i',{staticClass:"el-icon-arrow-left font-bold text-xl cursor-pointer",staticStyle:{"color":"#999999"},on:{"click":_vm.goBack}}),_c('p',{staticClass:"text-xl ml-4 font-bold",staticStyle:{"color":"#ff8e3b"}},[_vm._v(" "+_vm._s(_vm.categoryInfo.name)+" ")])])]),_c('div',{staticClass:"flex bg-white category-content"},[_c('div',{staticClass:"w-3/5 px-12 py-8 overflow-y-scroll category-info"},[_c('p',{staticClass:"font-bold text-lg mb-2"},[_vm._v("Introduction")]),_c('div',{staticClass:"content mb-4"},[_vm._v(_vm._s(_vm.categoryInfo.intro))]),_c('div',{staticClass:"content mb-4"}),_c('div',{staticClass:"content rich-text",domProps:{"innerHTML":_vm._s(_vm.categoryInfo.instructions)}})]),_c('div',{staticClass:"flex-1 px-12 py-8 overflow-y-scroll"},[_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"font-bold text-lg",staticStyle:{"color":"#ff8e3b"}},[_vm._v(" Nominate Students for This Category ")]),_c('div',[_c('p',{staticClass:"my-3"},[_vm._v("Method 1: Share this link with the student.")]),_c('div',{staticClass:"flex items-center"},[_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":true},model:{value:(_vm.shareLink),callback:function ($$v) {_vm.shareLink=$$v},expression:"shareLink"}}),_c('el-button',{staticClass:"share-copy-link",attrs:{"type":"primary","data-clipboard-text":_vm.shareLink},on:{"click":_vm.doShare}},[_vm._v("Copy")])],1)]),_c('div',[_c('p',{staticClass:"my-3"},[_vm._v("Method 2: Send an email to the student.")]),_c('div',{staticClass:"flex items-center"},[_c('el-form',{ref:"emailForm",staticClass:"w-full",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"prop":"email","rules":[
                {
                  required: true,
                  message: 'Please input email',
                  trigger: 'blur',
                },
                // {
                //   type: 'email',
                //   message: 'Please input correct email',
                //   trigger: ['blur', 'change'],
                // },
              ]}},[_c('el-input',{attrs:{"placeholder":"Please input email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('el-button',{staticClass:"share-copy-link",attrs:{"type":"primary"},on:{"click":_vm.onSend}},[_vm._v("Send")])],1)])]),_c('div',[_c('p',{staticClass:"font-bold text-lg",staticStyle:{"color":"#ff8e3b"}},[_vm._v(" Recently Registered Nominees ")]),(_vm.studentList.length > 0)?_c('div',{staticClass:"flex flex-wrap mt-5 gap-5"},_vm._l((_vm.studentList),function(item,index){return _c('div',{key:index,staticClass:"student-item flex items-center",staticStyle:{"width":"100%","padding-left":"0px","padding-right":"0px"}},[_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(`${item.student_info.student_givenName_pinyin || item.student_info.student_givenName }${" "} ${item.student_info.student_lastName_pinyin || item.student_info.student_lastName }`)+" ")]),_c('p',{staticClass:"text-xs flex-1 mr-2",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(item.student_info.student_email)+" ")]),_c('p',{staticClass:"text-sm flex-1"},[_vm._v(" "+_vm._s(_vm.$dayjs(item.create_time * 1000).format("MMMM D, YYYY"))+" ")])])}),0):_c('p',{staticClass:"mt-6 tips text-sm"},[_vm._v(" No students nominated in this category have completed their registration. ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }